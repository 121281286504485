<template>
  <div class="sidebar"
       ref="sidebar"
       :class="{on:!navStatus()}">
    <!--    @open="handleOpen"-->
    <!--    @close="handleClose"-->
    <el-menu
      style="border-right: none"
      :default-active="active"
      class="el-menu-vertical-demo"
      background-color="#444444"
      ref="menu"
      :default-openeds="openeds"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <template v-if="navType === 'crowdSourcingServices'">
        <el-menu-item
          v-permission="PERMISSIONS.releaseTask"
          title="新建任务"
          style="padding: 0;"
          @click="backTop"
          index="/releaseTask/releaseTask">
          <router-link
            slot="title"
            :to="{path:'/releaseTask/releaseTask'}"
            tag="div"
            class="route-item">
            <svg-icon
              class="icon"
              v-show="active==='/releaseTask/releaseTask'"
              style="width: 24px;height: 24px"
              icon-class="create-task-s">
            </svg-icon>
            <svg-icon
              class="icon"
              v-show="active!=='/releaseTask/releaseTask'"
              style="width: 24px;height: 24px"
              icon-class="create-task">
            </svg-icon>
            <template v-if="navStatus()">新建任务</template>
          </router-link>
        </el-menu-item>
        <el-menu-item
          style="padding: 0;"
          v-permission="{
            permission: PERMISSIONS.subpackage,
            condition: [userData.fastPackage === fastPackage]
          }"
          title="快捷分包"
          index="/subpackage/subpackage">
          <div
            slot="title"
             class="route-item"
             @click="goSubpackage"
            >
            <svg-icon
              style="width: 24px;height: 24px"
              class="icon"
              v-show="active==='/subpackage/subpackage'"
              icon-class="fast-package-s"
            >
            </svg-icon>
            <svg-icon
              style="width: 24px;height: 24px"
              class="icon"
              v-show="active!=='/subpackage/subpackage'"
              icon-class="fast-package"
            >
            </svg-icon>
            <template v-if="navStatus()">快捷分包</template>
          </div>
        </el-menu-item>
        <el-menu-item
          style="padding: 0;"
          v-permission="{
            permission: PERMISSIONS.subpackageLabour,
            condition: [userData.laborRemunerationFlag]
          }"
          v-if="userData.laborRemunerationFlag"
          title="劳务所得"
          index="/subpackageLabour/subpackageLabour">
          <div
            slot="title"
             class="route-item"
             @click="$router.push({
              path: '/subpackageLabour/subpackageLabour'
             })"
            >
            <svg-icon
              style="width: 24px;height: 24px"
              class="icon"
              v-show="active==='/subpackageLabour/subpackageLabour'"
              icon-class="fast-package-s"
            >
            </svg-icon>
            <svg-icon
              style="width: 24px;height: 24px"
              class="icon"
              v-show="active!=='/subpackageLabour/subpackageLabour'"
              icon-class="fast-package"
            >
            </svg-icon>
            <template v-if="navStatus()">劳务所得</template>
          </div>
        </el-menu-item>
      </template>
      <sidebar
        menu-index="4"
        :type="{
        key: 'type',
        value: navType
      }"
        :name="nameData[navType].name"
      ></sidebar>
      <sidebar
        menu-index="5"
        v-if="nameData[navType].otherMenu && nameData[navType].otherMenu.complianceManagement"
        :type="{
        key: 'otherType',
        value: nameData[navType].otherMenu.complianceManagement.key
      }"
        :name="nameData[navType].otherMenu.complianceManagement.name"
      ></sidebar>
      <sidebar
        menu-index="6"
        v-if="(
        companyAuthInfo.isDistributor || companyAuthInfo.isConglomerate
        ) && nameData[navType].otherMenu && nameData[navType].otherMenu.crowdSourcingServicesChannel"
        :type="{
        key: 'otherType',
        value: nameData[navType].otherMenu.crowdSourcingServicesChannel.key
      }"
        :name="nameData[navType].otherMenu.crowdSourcingServicesChannel.name"
      ></sidebar>
    </el-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import sidebar from './sidebar'
import { menuData, hroTelephone } from '@/api/config'
const fastPackage = 1 // 快捷分包
export default {
  inject: ['reload', 'navStatus', 'backTop'],
  props: {
    checkFlag: {
      default: true,
      type: Boolean
    },
    completeAuthInfoTipFlag: {
      default: false,
      type: Boolean
    }
  },
  components: { sidebar },
  data () {
    return {
      fastPackage,
      nameData: menuData,
      centerDialogVisible: false,
      authTipText: '',
      recruitType: null,
      releaseItemHover: null,
      navType: '',
      goSubpackageLoading: false, // 跳快捷分包防重复提交
      openeds: ['4']
    }
  },
  provide () {
    return {
      routeActive: {
        path: () => this.active
      }
    }
  },
  computed: {
    active () {
      const { path, matched } = this.$route
      const navKey = this.$router.currentRoute.query.navKey
      const routes = this.$router.getRoutes()
      let active = matched[0].path + matched[0].path
      if (navKey) {
        active = `/${navKey}/${navKey}`
      }
      if (routes.some((item, index) => item.path === active)) {
        return active
      }
      return path
    },
    ...mapGetters([
      'permission_routers',
      'sidebar',
      'userData',
      'companyAuthInfo',
      'salaryEpibolicComInfo'
    ])
  },
  created () {
    this.navType = this.$route.meta.type
  },
  mounted () {
  },
  methods: {
    goToAuth () {
      if (this.userData.authStatus !== 2) {
        this.$router.push({ path: '/company-cert/company-cert' })
      } else {
        this.$router.push({ path: '/company-info/company-info' })
      }
      this.centerDialogVisible = false
    },
    /**
     * 快捷分包
     */
    goSubpackage() {
      if (this.goSubpackageLoading) {
        return
      }
      this.backTop()
      this.goSubpackageLoading = true
      this.$store.dispatch('GetInfo').then(res => {
        if (!this.userData.existActiveSupply) {
          this.$alert(`<div style="text-align: left;color: #303133">服务商配置错误，请联系签订合同的平台商务或平台众包服务客服！平台众包客服电话：<span class="color-F56C6C">${hroTelephone}</span></div>`, '提示', {
            confirmButtonText: '知道了',
            customClass: 'customClassMsgBox',
            dangerouslyUseHTMLString: true
          })
          return
        }
        if (this.$route.path === '/subpackage/subpackage') {
          this.reload()
        } else {
          this.$router.push({
            path: '/subpackage/subpackage'
          })
        }
      }).catch(err => {
        console.info(err)
      }).finally(() => {
        this.goSubpackageLoading = false
      })
    }
  },
  watch: {
    $route () {
      this.navType = this.$route.meta.type
      this.recruitType = null
    }
  }
}
</script>

<style lang="less">
@import "../../../../styles/yxqn.less";
.route-item{
  .flex-normal();
    align-items: center;
    width: 260px;
    height: 100%;
    padding-left: 18px;
  box-sizing: border-box;
  .svg-icon{
    width: 24px!important;
    height: 24px!important;
    margin-right: 10px;
  }
}
</style>
<style lang="less" scoped>
  @import "index";
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    //width: 260px;
  }
</style>
<style scoped lang="less">

  ::v-deep .el-menu-item.is-active{
    background: #DEDEDE!important;
    color: #444444!important;
  }
</style>
