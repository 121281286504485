<template>
  <div class="app-wrapper">
    <div class="yxqn-header-c">
      <div class="app-header">
        <div @click="triggerNav" class="nav-icon" :class="{on: !isOpened}">
          <img src="./icon-operation.png" height="32" width="32"/>
        </div>
        <div class="header-logo">
          <div
            class="logo-jump"
            :style="pageConfig.isCustom?'':'cursor: pointer'"
            @click="jumpToHome"
          >
            <img :src="pageConfig.logoUrl"/>
          </div>
        </div>
        <div class="header-left">
          <div class="header-nav">
            <div
              :key="index"
              v-permission="{
                    permission: [item.permission],
                  }"
              @click="navigatorTo(item)"
              v-for="(item, index) in navData"
              class="header-nav-list"
              :class="{on: navType === item.type}"
            >{{ item.name }}
            </div>
          </div>
          <div class="log-out">
            <download-center :options="downloadOptions" download-center-style="position:static;margin-right:10px;">
            </download-center>
            <router-link class="header-msg" :to="{path: '/notice/notice'}">
              <span class="noticeNum" v-if="$store.getters.unread.notices">{{ $store.getters.unread.notices > 99 ? '99+' : $store.getters.unread.notices }}</span>
              消息
            </router-link>
            <div id="header-nav">
              <div class="header-info" ref="headerInfo">
                <div
                  v-if="userData.logo === ''&& !userData.logo"
                  class="header-nav-logo default-logo"
                >
                </div>
                <div
                  v-else class="header-nav-logo"
                  :style="`background-image: url(${userData.logo});background-size:100% 100%;`"
                >
                </div>
                <div class="header-nav-info">
                  <div class="info-phone">
                    {{ userData.phone | subStrStar }}
                  </div>
                  <div class="info-company">
                    {{ userData.companyName | subStr(7) }}
                  </div>
                </div>
              </div>
              <div class="header-nav-content">
                <div class="header-nav-list-page triangle">
                  <div class="header-nav-list ">
                    <router-link
                      v-permission="PERMISSIONS.companyInfo"
                      class="nav-list-item icon-company-info"
                      :to="{path: '/company-info/company-info'}"
                    >企业资料<span v-if="!checkFlag">待完善</span>
                    </router-link>
                    <router-link
                      class="nav-list-item icon-auth"
                      :to="{path: '/company-cert/company-cert'}"
                    >企业认证<span
                      v-if="!userData.authStatus"
                    >待认证</span>
                      <span v-else-if="userData.authStatus===1">认证中</span>
                      <span v-else-if="userData.authStatus===2">已认证</span>
                    </router-link>
                    <router-link
                      v-permission="{
                        permission: [PERMISSIONS.subAccount]
                      }"
                      class="nav-list-item icon-phone"
                      :to="{path: '/subAccount/subAccount'}"
                    >
                      子账号管理
                    </router-link>
                    <div class="nav-list-item_line"></div>
                    <router-link
                      v-permission="PERMISSIONS.modifyPhone"
                      class="nav-list-item icon-phone"
                      :to="{path: '/security/modify-phone'}"
                    >
                      修改登录手机号
                    </router-link>
                    <router-link
                      v-permission="PERMISSIONS.modifyPassword"
                      class="nav-list-item icon-password"
                      :to="{path: '/security/modify-password'}"
                    >
                      修改登录密码
                    </router-link>
                    <div class="nav-list-item_line"></div>
                    <div @click="logOut" class="nav-list-item icon-out">
                      退出登录
                    </div>
                  </div>
                </div>
              </div>
              <i class="el-icon-arrow-down nav-arrow"></i>
            </div>
            <!--&nbsp;&nbsp;&nbsp;&nbsp;{{$store.getters.userData.companyName?$store.getters.userData.companyName:phone}}&nbsp;&nbsp;&nbsp;-->
            <!--<a href="javascript:" @click="logOut" class="logout"></a>-->
          </div>
        </div>
      </div>
    </div>
    <div class="app-container clearfix">
      <div class="zb-not-bound" v-if="navType === 'crowdSourcingServices' && !userData.linkSupply ">
        <img src="./zb-not-bound.png" height="500" width="760"/>
        <div class="zb-not-bound-text">
          您的企业还未开通众包服务，如有需要请联系官方客服<br/>
          客服电话：<span style="color:#409EFF;">{{ CSSTelephone }}</span>（ 工作日 09：30-21:30 ）
        </div>
      </div>
      <template v-else>
        <div class="sidebar-container" :class="{on:!isOpened}">
          <sidebar
            :completeAuthInfoTipFlag="completeAuthInfoTipFlag"
            :checkFlag="checkFlag"
          ></sidebar>
        </div>
        <div class="main-page" :class="{on:!isOpened}">
          <app-main ref="mainPage" v-if="inRouterAlive"></app-main>
        </div>
      </template>
    </div>
    <customDialog
      style="z-index: 2000"
      ref="companyAuthDialog"
      :options="{titleHeight:60,titleFontSize:22}"
    >
      <template slot="title">
        <span>营业执照认证</span>
      </template>
      <template>
        <div class="new-company-auth-container ">
          <el-form
            class="agent-auth-form" ref="companyInfoForm"
            :validate-on-rule-change="false"
            :model="companyInfoForm"
            :rules="companyAuthRules"
            label-position="right"
            label-width="140px"
          >
            <div class="dialog-item-title b-20">营业执照信息</div>
            <img v-if="businessImgUrl" class="license-img" :src="businessImgUrl" alt="">
            <div class="form-item">
              <el-form-item label="企业全称:" prop="name" class="requiredTip">
                <el-input
                  class="auth-input"
                  v-if="!!comAuth.companyName"
                  :disabled="true"
                  :value="companyInfoForm.name"
                  placeholder="请输入企业全称"
                  maxlength="50"
                ></el-input>
                <el-input
                  class="auth-input"
                  v-else
                  v-model="companyInfoForm.name"
                  placeholder="请输入企业全称"
                  maxlength="50"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="营业执照代码:" prop="orgCode" class="requiredTip">
                <el-input
                  class="auth-input"
                  v-model="companyInfoForm.orgCode"
                  placeholder="请输入营业执照代码"
                  maxlength="30"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="法定代表人:" prop="legalRepName" class="requiredTip">
                <el-input
                  class="auth-input"
                  v-model="companyInfoForm.legalRepName"
                  placeholder="请输入法定代表人"
                  maxlength="10"
                >

                </el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="法人身份证:" prop="legalRepIdNo" class="requiredTip">
                <el-input
                  class="auth-input"
                  v-model="companyInfoForm.legalRepIdNo"
                  placeholder="请输入法人身份证"
                  maxlength="18"
                ></el-input>
              </el-form-item>
            </div>
            <div style="text-align: center">
              <div class="default-btn btn-primary-gradient" @click="companyAuthSubmit">确认</div>
            </div>
          </el-form>
        </div>
      </template>
    </customDialog>
  </div>
</template>

<script>
import { PROJECT_NAME } from '@/config'
import customDialog from '@/components/customDialog/customDialog'
import { Sidebar, AppMain } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { unreadNotice } from 'api/layout'
import { getCompanyInfo } from 'api/job'
import {
  salaryEpibolicRequestCode,
  statusCode,
  authStatus,
  hroTelephone,
  CSSTelephone,
  layoutNavData
} from 'api/config'
import { mapGetters } from 'vuex'

import { validateRules } from '@/validate'
import { fourElements } from 'api/account'
import { addDownloadTimes, getDownloadList, getDownloadResult } from 'api/common'

export default {
  name: 'layout',
  components: {
    Sidebar,
    AppMain,
    customDialog
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    ...mapGetters([
      'userData',
      'salaryEpibolicComInfo',
      'pageConfig',
      'companyAuthInfo',
      'allRoutePath'
    ]),
    companyAuthRules() {
      const key = ['name', 'legalRepName', 'orgCode', 'legalRepIdNo']
      return this.createFormRules(key)
    }
  },
  provide() {
    return {
      reload: this.reload,
      goCompleteAuth: this.goCompleteAuth,
      navStatus: () => this.isOpened,
      backTop: this.backTop
    }
  },
  data() {
    return {
      PROJECT_NAME,
      CSSTelephone,
      hroTelephone,
      authStatus,
      navData: layoutNavData,
      completeAuthInfoTipPage: ['/payoff/payoff', '/taskManagement/taskManagement', '/myIndex/my-index'], // 1 招聘服务 2 薪酬外包/众包
      completeAuthInfoTipFlag: false,
      comAuth: {},
      companyInfoForm: { // 企业认证表单
        businessImgUrl: '',
        name: '',
        legalRepName: '',
        orgCode: '',
        legalRepIdNo: ''
      },
      businessImgUrl: '',
      checkFlag: true,
      headerNav: false,
      submitLoading: null,
      unreadNotice: '',
      navType: '',
      qrCodeChecked: 1, // 1公众号 2求职 3招聘
      inRouterAlive: true,
      downloadOptions: {
        getTasksFun: getDownloadList,
        addDownloadTimesFun: addDownloadTimes,
        getDownloadResultFun: getDownloadResult
      },
      isOpened: true
    }
  },
  created() {
    this.navType = this.$route.meta.type
    if (this.$store.getters.loginTipForPassword) {
      this.$confirm('提示：您当前使用的是系统设置的初始密码，为了您的账号安全，建议您修改密码', '登录成功！', {
        confirmButtonText: '立即修改',
        cancelButtonText: '暂不修改',
        customClass: 'customClassMsgBox',
        closeOnClickModal: false
      }).then(() => {
        this.$router.push('/security/modify-password')
      }).catch(() => {

      })
    }
  },
  mounted() {
    this.getCompanyAuthInfo()
    unreadNotice().then(res => {
      this.$store.dispatch('setUnread', res.data)
    }).catch((err) => {
      console.log(err)
    })
    this.getCompanyInfo()
  },
  methods: {
    navigatorTo(nav) {
      const path = this.allRoutePath.hash[nav.path]
      console.log(this.$store.getters.permission)
      const typeFirstPath = this.allRoutePath.typeFirstPath[nav.type]
      this.$router.push(path || typeFirstPath)
    },
    backTop() {
      const mainPage = this.$refs.mainPage.$el
      mainPage.scrollTop = 0
    },
    triggerNav() {
      this.isOpened = !this.isOpened
    },
    jumpToHome() {
      if (this.pageConfig.isCustom) {
        return
      }
      window.location.href = this.$baseInfo.homePage
    },
    /**
     * 获取认证信息  四要素信息
     * @returns {Promise<void>}
     */
    async getCompanyAuthInfo() {
      await this.$store.dispatch('getCompanyAuthInfo').then()
      await this.$store.dispatch('getSalaryEpibolicComInfo')
      this.comAuth = this.$store.getters.companyAuthInfo
      this.businessImgUrl = this.comAuth.url || ''
      this.companyInfoForm.businessImgUrl = this.comAuth.businessImgUrl || ''
      this.companyInfoForm.name = this.comAuth.companyName || this.salaryEpibolicComInfo.name || ''
      this.companyInfoForm.orgCode = this.comAuth.businessLicenseNo || this.salaryEpibolicComInfo.orgCode || ''
      this.companyInfoForm.legalRepName = this.salaryEpibolicComInfo.legalRepName || ''
      this.companyInfoForm.legalRepIdNo = this.salaryEpibolicComInfo.legalRepIdNo || ''
      this.isCompleteAuthInfo()
    },
    /**
     * 判断认证信息是否已完善
     */
    isCompleteAuthInfo() {
      // let cFourFactorsStatusFlag = (authStatus.C_FOUR_FACTORS_STATUS.SUCCESS === this.salaryEpibolicComInfo.cFourFactorsStatus)
      const objKey = ['name', 'legalRepName', 'orgCode', 'legalRepIdNo']
      const completeFlag = objKey.every((val) => this.companyInfoForm[val])

      const myIndexFlag = this.$route.path === '/myIndex/my-index' && this.comAuth.checkStatus === 2 && !completeFlag
      const otherFlag = ['/payoff/payoff', '/taskManagement/taskManagement'].indexOf(this.$route.path) > -1 && this.comAuth.checkStatus === 2 && !completeFlag
      if (myIndexFlag || otherFlag) {
        this.completeAuthInfoTipFlag = true
        return
      }
      // if (['/payoff/payoff', '/taskManagement/taskManagement'].indexOf(this.$route.path) > -1 && !cFourFactorsStatusFlag && this.comAuth.checkStatus === 2 && !completeFlag) {
      //   this.completeAuthInfoTipFlag = 2
      //   return
      // }
      this.completeAuthInfoTipFlag = false
    },
    /**
     * 去完善
     */
    goCompleteAuth(flag) {
      if (flag) {
        this.$refs.companyAuthDialog.show()
      } else {
        this.$router.push({
          path: '/company-cert/company-cert'
        })
      }
    },
    /**
     * 组合表单验证规则
     * @param key
     */
    createFormRules(key) {
      const result = {}
      key.forEach(item => {
        result[item] = validateRules[item]
      })
      return result
    },
    showErrorMsg(msg, title = '温馨提示') {
      this.$alert(msg, title, {
        center: true,
        customClass: 'payoff-1-err-msg',
        confirmButtonText: '我知道了',
        dangerouslyUseHTMLString: true
      })
    },
    /**
     * 企业认证表单验证
     */
    companyAuthSubmit() {
      this.$nextTick(() => {
        this.$refs.companyInfoForm.validate(valid => {
          if (valid) {
            this.submitLoading = this.$loading({
              lock: true,
              text: '保存中，请稍后',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            const params = {
              ...this.companyInfoForm
            }
            fourElements(params).then(res => {
              if (this.$route.path === '/company-cert/company-cert') {
                this.reload()
              }
              this.$refs.companyAuthDialog.hide()
              this.getCompanyAuthInfo()
            }).catch(err => {
              if (err.code === salaryEpibolicRequestCode.COMPANY_AUTH_NO_NAME ||
                err.code === salaryEpibolicRequestCode.COMPANY_AUTH_NOT_PASS) {
                this.showErrorMsg(err.msg.replace('参数错误：', ''), '错误提示')
              }
            }).finally(() => {
              this.submitLoading.close()
            })
          } else {
            return false
          }
        })
      })
    },
    logOut() {
      const pageType = sessionStorage.getItem('pageType') || 'crowdSourcingServices'
      this.$store.dispatch('LogOut').then(res => {
        this.$router.push({ path: '/login', query: { type: pageType } })
      }).catch(() => {
        this.$router.push({ path: '/login', query: { type: pageType } })
      })
    },
    getCompanyInfo() {
      // 检查角色和企业资料
      getCompanyInfo().then(res => {
        if (res.code === statusCode.OK) {
          this.checkFlag = !(res.data && !res.data.abbr)
        }
      }).catch(() => {
        this.$message.error('服务器错误!!')
      })
    },
    // 无闪动刷新页面
    reload() {
      this.inRouterAlive = false
      this.$nextTick(() => {
        this.inRouterAlive = true
      })
    }
  },
  watch: {
    $route(to, f) {
      // 完善认证信息
      if (this.completeAuthInfoTipPage.indexOf(this.$route.path) > -1) {
        this.isCompleteAuthInfo()
        !this.completeAuthInfoTipFlag && this.getCompanyAuthInfo()
      }

      this.navType = this.$route.meta.type
      if (f.path === '/company-info/company-info') {
        this.getCompanyInfo()
      }
      this.$store.dispatch('GetInfo')
    }
  }
}
</script>

<style lang="less">
.app-wrapper {
  .confirm-money-container, .agent-auth-container {
    .auth-form, .agent-auth-form, .new-company-auth-container {
      label.el-form-item__label {
        color: #333;
        font-size: 16px !important;
      }

      .requiredTipLeft label.el-form-item__label {
        &:before {
          content: "*" !important;
          color: #FF4B28;
          margin-right: 4px !important;
          font-size: 16px !important;
        }

        &:after {
          content: '' !important;
        }
      }

      .auth-input {
        input {
          width: 286px !important;
          height: 32px !important;
        }
      }
    }

    .money-form {
      label.el-form-item__label {
        color: #333;
        font-size: 18px !important;
      }

      input {
        width: 296px !important;
        height: 44px !important;
      }
    }
  }
}
</style>
<style lang="less" scoped>
@import "Layout";
</style>

