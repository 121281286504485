/**
 *@desc 账号相 关
 */
import request from '@/utils/request'
import { salaryEpibolicRequestCode, statusCode } from 'api/config'

/**
 * 营业执照OCR识别
 */
export const licenseOCR = data => request.get(
  '/api/console/esign/ocr/license',
  {
    params: data,
    headers: {
      ignoreCode: [
        statusCode.LICENSE_OCR_FAIL,
        statusCode.LICENSE_OCR_LIMIT
      ]
    }
  }
)

/**
 * 企业四要素认证
 */
export const fourElements = data => request.post(
  '/api/console/company/auth',
  data,
  {
    headers: {
      ignoreCode: [
        salaryEpibolicRequestCode.COMPANY_AUTH_NO_NAME,
        salaryEpibolicRequestCode.COMPANY_AUTH_NOT_PASS
      ]
    }
  }
)

/**
 * 对公账户验证
 */
export const randomPay = data => request.post(
  '/api/console/esign/company/randomPay',
  data,
  {
    headers: {
      ignoreCode: [
        salaryEpibolicRequestCode.BANK_ERR
      ]
    }
  }
)

/**
 * 提交人力资源认证
 */
export const hrAuth = data => request.post('/api/console/company/auth/hr', data)

/**
 * @desc 获取子账号列表
 */
export const getSubAccounts = params => request.get('/api/console/queryUsers', { params })

/**
 * @desc 获取所以角色
 */
export const getRoles = params => request.get('/api/console/auth/roleList', { params })


/**
 * @desc 获取当前用户的所有菜单
 * @param params
 */
export const getAllMenu = params =>
  request.get('/api/console/auth/comUser/permissionTree', { params })

/**
 * @desc 添加子账号
 * @param data
 */
export const addSubAccount = data =>
  request.post('/api/console/addCompanyUserAndRole', data)

/**
 * @desc 修改子账号
 * @param data
 */
export const updateSubAccount = data =>
  request.post('/api/console/updateCompanyUserAndRole', data)

/**
 * @desc 删除子账号
 * @param data
 */
export const delSubAccount = data =>
  request.post('/api/console/delCompanyUser', data)
