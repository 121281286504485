
const isPrerender = window.__PRERENDER_INJECTED__ === 'prerender'
if (process.env.VUE_APP_RUNTIME === 'production') {
  /* eslint-disable */
  __webpack_public_path__ = isPrerender ? 'http://localhost:8001/' : 'https://s.youweiwork.com/pcweb/'
}
if (process.env.VUE_APP_RUNTIME === 'test') {
  /* eslint-disable */
  __webpack_public_path__ = isPrerender ? 'http://localhost:8001/' : '/'
}
