var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"sidebar",staticClass:"sidebar",class:{on:!_vm.navStatus()}},[_c('el-menu',{ref:"menu",staticClass:"el-menu-vertical-demo",staticStyle:{"border-right":"none"},attrs:{"default-active":_vm.active,"background-color":"#444444","default-openeds":_vm.openeds,"text-color":"#fff","active-text-color":"#ffd04b"}},[(_vm.navType === 'crowdSourcingServices')?[_c('el-menu-item',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.PERMISSIONS.releaseTask),expression:"PERMISSIONS.releaseTask"}],staticStyle:{"padding":"0"},attrs:{"title":"新建任务","index":"/releaseTask/releaseTask"},on:{"click":_vm.backTop}},[_c('router-link',{staticClass:"route-item",attrs:{"slot":"title","to":{path:'/releaseTask/releaseTask'},"tag":"div"},slot:"title"},[_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.active==='/releaseTask/releaseTask'),expression:"active==='/releaseTask/releaseTask'"}],staticClass:"icon",staticStyle:{"width":"24px","height":"24px"},attrs:{"icon-class":"create-task-s"}}),_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.active!=='/releaseTask/releaseTask'),expression:"active!=='/releaseTask/releaseTask'"}],staticClass:"icon",staticStyle:{"width":"24px","height":"24px"},attrs:{"icon-class":"create-task"}}),(_vm.navStatus())?[_vm._v("新建任务")]:_vm._e()],2)],1),_c('el-menu-item',{directives:[{name:"permission",rawName:"v-permission",value:({
          permission: _vm.PERMISSIONS.subpackage,
          condition: [_vm.userData.fastPackage === _vm.fastPackage]
        }),expression:"{\n          permission: PERMISSIONS.subpackage,\n          condition: [userData.fastPackage === fastPackage]\n        }"}],staticStyle:{"padding":"0"},attrs:{"title":"快捷分包","index":"/subpackage/subpackage"}},[_c('div',{staticClass:"route-item",attrs:{"slot":"title"},on:{"click":_vm.goSubpackage},slot:"title"},[_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.active==='/subpackage/subpackage'),expression:"active==='/subpackage/subpackage'"}],staticClass:"icon",staticStyle:{"width":"24px","height":"24px"},attrs:{"icon-class":"fast-package-s"}}),_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.active!=='/subpackage/subpackage'),expression:"active!=='/subpackage/subpackage'"}],staticClass:"icon",staticStyle:{"width":"24px","height":"24px"},attrs:{"icon-class":"fast-package"}}),(_vm.navStatus())?[_vm._v("快捷分包")]:_vm._e()],2)]),(_vm.userData.laborRemunerationFlag)?_c('el-menu-item',{directives:[{name:"permission",rawName:"v-permission",value:({
          permission: _vm.PERMISSIONS.subpackageLabour,
          condition: [_vm.userData.laborRemunerationFlag]
        }),expression:"{\n          permission: PERMISSIONS.subpackageLabour,\n          condition: [userData.laborRemunerationFlag]\n        }"}],staticStyle:{"padding":"0"},attrs:{"title":"劳务所得","index":"/subpackageLabour/subpackageLabour"}},[_c('div',{staticClass:"route-item",attrs:{"slot":"title"},on:{"click":function($event){return _vm.$router.push({
            path: '/subpackageLabour/subpackageLabour'
           })}},slot:"title"},[_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.active==='/subpackageLabour/subpackageLabour'),expression:"active==='/subpackageLabour/subpackageLabour'"}],staticClass:"icon",staticStyle:{"width":"24px","height":"24px"},attrs:{"icon-class":"fast-package-s"}}),_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.active!=='/subpackageLabour/subpackageLabour'),expression:"active!=='/subpackageLabour/subpackageLabour'"}],staticClass:"icon",staticStyle:{"width":"24px","height":"24px"},attrs:{"icon-class":"fast-package"}}),(_vm.navStatus())?[_vm._v("劳务所得")]:_vm._e()],2)]):_vm._e()]:_vm._e(),_c('sidebar',{attrs:{"menu-index":"4","type":{
      key: 'type',
      value: _vm.navType
    },"name":_vm.nameData[_vm.navType].name}}),(_vm.nameData[_vm.navType].otherMenu && _vm.nameData[_vm.navType].otherMenu.complianceManagement)?_c('sidebar',{attrs:{"menu-index":"5","type":{
      key: 'otherType',
      value: _vm.nameData[_vm.navType].otherMenu.complianceManagement.key
    },"name":_vm.nameData[_vm.navType].otherMenu.complianceManagement.name}}):_vm._e(),((
      _vm.companyAuthInfo.isDistributor || _vm.companyAuthInfo.isConglomerate
      ) && _vm.nameData[_vm.navType].otherMenu && _vm.nameData[_vm.navType].otherMenu.crowdSourcingServicesChannel)?_c('sidebar',{attrs:{"menu-index":"6","type":{
      key: 'otherType',
      value: _vm.nameData[_vm.navType].otherMenu.crowdSourcingServicesChannel.key
    },"name":_vm.nameData[_vm.navType].otherMenu.crowdSourcingServicesChannel.name}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }