const requireAll = requireContext => requireContext.keys().map(requireContext)
const imgList = []
imgList.push(require.context('./index/aboutUs', false, /(\.png|\.jpeg)$/))
imgList.push(require.context('./index/employment', false, /(\.png|\.jpeg)$/))
imgList.push(require.context('./index/home/img', false))
imgList.push(require.context('./index/home/banner', false))
imgList.push(require.context('./index/insuranceAgency', false, /(\.png|\.jpeg)$/))
imgList.push(require.context('./index/lingyongtongPage', false, /(\.png|\.jpeg)$/))
imgList.push(require.context('./index/parkOperation/images', false, /(\.png|\.jpeg)$/))
imgList.push(require.context('./index/youweiCloud/images', false, /(\.png|\.jpeg)$/))
imgList.push(require.context('./index/youzhiRecruit', false, /(\.png|\.jpeg)$/))

for (let i = 0; i < imgList.length; i++) {
  requireAll(imgList[i])
}


