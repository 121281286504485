/**
 * Created by jiachenpan on 16/11/18.
 */
import store from '@/store'
import Vue from 'vue'
import { Loading } from 'element-ui'
import { getAsyncTaskResult } from 'api/common'
import { ASYNC_TASK_RESULT_STATUS, statusCode } from 'api/config'
export function debounce (func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

export function formatTime (time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

export function createUploadPath (fileName) {
  const oldName = fileName.split('.').splice(-2, 1)[0] || ''
  const fileType = fileName.split('.').pop()
  const userId = store.getters.userData.comUserId
  const myDate = new Date()
  // const Y = myDate.getFullYear()
  // const M = myDate.getMonth() + 1
  const D = myDate.getDate()
  const name = 'xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
  return `${D}/${userId || ''}-${name}_-_${oldName.substr(0, 100)}.${fileType}`
}


/**
 * 获取定位信息
 * @returns {Promise}
 */
export const getCurrentPosition = (() => {
  async function isInitMapOk () {
    if (window.AMap && window.AMap.Geocoder) {
      return new Promise((resolve, reject) => {
        const geolocation = new window.AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 5000,
          convert: true,
          showButton: false,
          buttonPosition: 'LB',
          showMarker: false,
          showCircle: false,
          panToLocation: false,
          zoomToAccuracy: false
        })
        geolocation.getCurrentPosition(async function (status, result) {
          if (status === 'complete') {
            resolve(result)
          } else {
            reject(result)
          }
        })
      })
    } else {
      await new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, 1000)
      })
      return isInitMapOk()
    }
  }

  return isInitMapOk()
})()

export function checkWebpFeature () {
  let flag = localStorage.getItem('checkWebpFeature')
  if (!flag) {
    flag = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
    localStorage.setItem('checkWebpFeature', flag.toString())
  }
  return flag.toString() === 'true'
}

export function isWebp (url) {
  let webpUrl = ''
  if (checkWebpFeature() && !url.includes(';base64,')) {
    webpUrl = url + '?x-oss-process=image/format,webp/quality,q_90'
  }
  return webpUrl
}

/**
 * 轮询异步任务的执行结果
 * @param finished 完成回调
 * @param fail 失败或错误回调
 * @param complete 结束回调
 * @param query 传递的查询参数，target必传
 * @param text loading态的文字提示
 */
export function PollingTaskResult({
  finished,
  fail,
  complete,
  query,
  text = '处理中，请稍等'
}) {
  let getAsyncTaskResultTimer = null
  if (!query.target) {
    throw new Error('缺少异步任务查询target参数')
  }
  const loading = Loading.service({
    text,
    lock: true,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  const oldComplete = complete || function() {}
  // 在结束回调关闭loading
  complete = result => {
    loading.close()
    oldComplete(result)
  }
  this.clean = () => {
    clearTimeout(getAsyncTaskResultTimer)
  }
  const poll = () => {
    getAsyncTaskResult(query)
      .then(res => {
        const resultStatus = res.data.status
        const result = res.data.result
        if (resultStatus === ASYNC_TASK_RESULT_STATUS.FINISHED) {
          if (result.code !== statusCode.OK) {
            fail && fail(result)
          } else {
            finished && finished(result)
          }
          Vue.nextTick(() => {
            complete(result)
          })
        } else {
          clearTimeout(getAsyncTaskResultTimer)
          getAsyncTaskResultTimer = setTimeout(() => {
            poll()
          }, 3000)
        }
      })
      .catch(
        Vue.prototype.$showError(err => {
          fail && fail(err)
          complete()
        })
      )
  }
  return poll()
}

export function toHash(
  l,
  k
) {
  const key = k
  const list = l.filter((item) => item[k] !== '') || []
  if (!Array.isArray(list)) {
    throw new TypeError('`list` must be of type Array')
  }
  return list.reduce((accum, item) => {
    if (item && item[key] != null) {
      accum[item[key]] = item
    }
    return accum
  }, {})
}
