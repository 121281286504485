import { routerConfig } from '@/router/modules'
import { getAllMenu } from 'api/account'
import { toHash } from '@/utils'
import Layout from 'views/layout/Layout.vue'
const constantRouterMap = routerConfig.constantRouterMap


/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 */
const allPath = {
  list: [],
  hash: {},
  typeFirstPath: {}
}
function filterAsyncRouter (routers, name, pPath = '') {
  // 遍历后台传来的路由字符串，转换为组件对象
  return routers.filter(router => {
    let options = router.options || '{}'
    options = JSON.parse(options)
    router.path = router.path || ''
    router.name = router.componentName || ''
    router.type = options.type || ''
    router.otherType = options.otherType || ''
    if (options.redirect) {
      router.redirect = options.redirect
    }
    router.meta = {
      icon: router.icon || '',
      noCache: !router.cache || true,
      title: router.menuName,
      hidden: router.hidden,
      pid: router.pid,
      pName: name || router.pName,
      ...options
    }
    router.name = router.componentName
    if (router.pageType === 1 && router.levelNum === 2) {
      // Layout组件特殊处理
      router.component = Layout
    } else {
      const component = router.componentPath
      if (!component) {
        console.log('页面路径未配置：', router)
      }
      if (pPath && pPath.startsWith('/')) {
        allPath.list.push({
          type: router.type,
          path: `${pPath}/${router.path}`
        })
        if (!allPath.typeFirstPath[router.type]) {
          allPath.typeFirstPath[router.type] = `${pPath}/${router.path}`
        }
      }
      router.component = loadView(component, router)
    }
    if (router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children, router.pName, router.path)
    }
    return true
  })
}
export const loadView = (view, i) => {
  if (!view) {
    console.log(i)
  }
  // eslint-disable-next-line
  return resolve => require([`@/views/${view}`], resolve)
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    originRouters: [],
    permission: [],
    routeTypes: [],
    allRoutePath: {
      list: [],
      hash: {},
      typeFirstPath: {}
    }
  },
  mutations: {
    ADD_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    SET_ORIGIN_ROUTERS: (state, originRouters) => {
      state.originRouters = originRouters
    },
    SET_PERMISSION: (state, permission) => {
      state.permission = permission
    },
    SET_ROUTE_TYPES: (state, routeTypes) => {
      state.routeTypes = routeTypes
    },
    SET_ALL_ROUTE_PATH: (state, allRoutePath) => {
      state.allRoutePath = allRoutePath
    }
  },
  actions: {
    cleanPermission({ commit }) {
      commit('SET_ORIGIN_ROUTERS', [])
      commit('SET_ROUTERS', constantRouterMap)
      commit('SET_PERMISSION', [])
      commit('SET_ROUTE_TYPES', [])
      commit('SET_ALL_ROUTE_PATH', {
        list: [],
        hash: {},
        typeFirstPath: {}
      })
    },
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        getAllMenu({
          filter: true
        })
          .then(({ data }) => {
            let newTree = []
            const { tree, permission } = data
            const routeTypes = []
            tree.forEach(itemOne => {
              let options = itemOne.options || '{}'
              options = JSON.parse(options)
              routeTypes.push(options.type)
              itemOne.children.forEach(item => {
                item.pName = itemOne.menuName
                newTree = newTree.concat(item)
              })
            })
            // 先清空
            allPath.hash = {}
            allPath.list = []
            allPath.typeFirstPath = {}
            const asyncRouter = filterAsyncRouter(newTree)
            allPath.hash = toHash(allPath.list, 'path')
            asyncRouter.push({ path: '*', redirect: '/404', hidden: true })
            commit('SET_ORIGIN_ROUTERS', tree)
            commit('SET_ROUTERS', asyncRouter)
            commit('SET_PERMISSION', permission || [])
            commit('SET_ROUTE_TYPES', routeTypes || [])
            commit('SET_ALL_ROUTE_PATH', allPath)
            resolve()
          })
          .catch(err => {
            console.log(err)
          })
      })
    }
  }
}

export default permission
