import { PROJECT_NAME } from '@/config'
import { PERMISSIONS } from '@/directives/global/permission/permission.config'

export const baseInfo = {
  name: '有为工作',
  homePage: '/',
  companyName: '四达铭铺（深圳）投资控股有限公司',
  type: PROJECT_NAME.ywWork,
  logo: require('../../assets/pageConfig/ywWork/logo.png'),
  copyright: '四达铭铺（深圳）投资控股有限公司版权所有Copyright © 2023',
  beian: '粤ICP备2020088862号',
  isShowICP: false,
  icpNo: '',
  icpUrl: '',
  ossUrl: 'https://s.youweiwork.com',
  ossBucket: 'yxyr-web',
  GDKye: 'a81cf722d68e51c69677ea473a23a0d7',
  CSSTelephone: '0755-23480450',
  layoutNavData: [
    {
      name: '众包服务',
      path: '/payrollRecord/payrollRecord',
      type: 'crowdSourcingServices',
      permission: PERMISSIONS.crowdSourcingServices
    }
  ]
}
