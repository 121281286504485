<template>
  <section class="custom-dialog" v-if="showContainer" @click="coverHandle">
    <section class="custom-dialog-cover" @click="coverHandle" v-if="showContainer && config.showCover"></section>
    <section class="custom-dialog-container" :style="containerStyle" :class="{open:showComponent}">
      <header class="custom-dialog-header" :style="headerStyle">
        <div class="custom-dialog-title" v-if="!config.title">
          <slot name="title">提示</slot>
        </div>
        <div class="custom-dialog-title" v-else>
          {{config.title}}
        </div>
        <div class="custom-dialog-close el-icon-close" v-if="config.showClose" @click.stop="hide"></div>
      </header>
      <main class="custom-dialog-main" ref="container">
        <slot>内容区域</slot>
      </main>
    </section>
  </section>
</template>

<script>
export default {
  name: 'customDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: null,
      default: ''
    },
    options: {
      type: Object,
      default: () => {
        return {
          showCover: true,
          coverClose: false,
          showClose: true
        }
      }
    }
  },
  data () {
    return {
      showContainer: false,
      showComponent: false,
      containerStyle: '',
      defaultOptions: {
        showCover: true, // 是否展示蒙层
        coverClose: false, // 点击蒙层是否关闭弹窗
        showClose: true, // 是否展示关闭图标
        titleHeight: 44, // 标题所占高度
        titleFontSize: 18,
        top: '100px',
        title: '',
        titleBackground: 'linear-gradient(90deg, rgba(95, 95, 95, 1) 0%, rgba(66, 66, 66, 1) 100%)' // 标题部分的背景
      }
    }
  },
  computed: {
    config () {
      return Object.assign({}, this.defaultOptions, this.options)
    },
    headerStyle () {
      return `height:${this.config.titleHeight}px;background:${this.config.titleBackground};font-size:${this.config.titleFontSize}px;`
    }
  },
  destroyed () {
    document.body.style.overflow = ''
  },
  methods: {
    coverHandle (event) {
      if (this.options.coverClose && event.target.className === 'custom-dialog') {
        this.hide()
        this.$emit('hide')
        console.log('派发hide事件-点击cover')
      }
    },
    show () {
      this.showContainer = true
      this.$nextTick(() => {
        if (this.width) {
          this.$refs.container.style.width = this.width
        }
        const containerClientWidth = this.$refs.container.querySelector('div').clientWidth
        const containerClientHeight = this.$refs.container.querySelector('div').clientHeight
        this.containerStyle = `width:${containerClientWidth}px;height:${containerClientHeight}px;margin:${this.config.top} auto;`
      })
      setTimeout(() => {
        this.showComponent = true
      }, 20)
      document.body.style.overflow = 'hidden'
      this.$emit('show')
    },
    hide () {
      this.showComponent = false
      setTimeout(() => {
        this.showContainer = false
      }, 200)
      document.body.style.overflow = ''
      this.$emit('update:visible', false)
      this.$emit('hide')
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.show()
      } else {
        this.hide()
      }
    }
  }
}
</script>

<style scoped lang="less">
  .custom-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.3);
    overflow: auto;
  }

  /*.custom-dialog-cover {*/
  /*position: fixed;*/
  /*width: 100%;*/
  /*height: 100%;*/
  /*top: 0;*/
  /*left: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*z-index: 1000;*/
  /*background: rgba(0, 0, 0, 0.3);*/
  /*}*/

  .custom-dialog-container {
    opacity: 0;
    transition: transform .2s ease-out, opacity .2s ease-out;
    border-radius: 8px;
    z-index: 2001;
    background: #ffffff;
    transform: scale(0.5, 0.5);
    &.open {
      opacity: 1;
      transform: scale(1, 1);
    }
    .custom-dialog-header {
      user-select: none;
      width: 100%;
      border-radius: 8px 8px 0 0;
      box-sizing: border-box;
      background: #333;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .custom-dialog-title {
        color: #FFFFFF;
      }
      .custom-dialog-close {
        cursor: pointer;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: 20px;
        font-size: 20px;
        color: #FFFFFF;
      }
    }
    .custom-dialog-main {
      min-width: 400px;
      min-height: 100px;
      background: #ffffff;
      width: 100%;
      box-sizing: border-box;
      border-radius: 0 0 8px 8px;
      margin-bottom: 20px;
    }
  }
</style>
