<template>
  <section class="app-main">
    <!--面包屑-->
    <div class="auth-tip space-between"
         v-if="userData.authStatus === 3 && $route.path === '/myIndex/my-index'">
      <span>您所提交的企业资质认证审核，未通过审核，驳回理由：{{companyAuthInfo.auditFailReason}}</span>
      <span class="auth-tip_btn" @click="goCompleteAuth(false)">重新认证</span>
    </div>
    <bread-crumbs></bread-crumbs>
    <keep-alive :include="keepAliveIncludes">
      <router-view @directType="directJobId" :refresh="refresh"></router-view>
    </keep-alive>
  </section>
</template>

<script>
import breadCrumbs from './breadCrumbs/breadCrumbs'
import { mapGetters } from 'vuex'

export default {
  components: { breadCrumbs },
  inject: ['goCompleteAuth'],
  name: 'AppMain',
  data () {
    return {
      directTipType: {
        type: '',
        endTime: '',
        jobId: ''
      },
      refresh: false,
      authTipArr: ['auth-tip-direct-no', 'auth-tip-direct-on', 'auth-tip-direct-off'],
      rechargePay: 0,
      directEndTime: '',
      keepAliveIncludes: [],
      subPath: []
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'salaryEpibolicComInfo',
      'companyAuthInfo'
    ])
  },
  async created () {
  },
  mounted () {
  },
  methods: {
    directJobId (val) {
      this.directTipType = val
      let dayTime = 0
      dayTime = ((this.directTipType.endTime - new Date().getTime()) / 86400000)
      dayTime = Math.round(dayTime)
      if (dayTime < 1) {
        dayTime = 1
      }
      this.directTipType.endTime = dayTime + '天'
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (t, f) {
        // 缓存页面
        const pathArr = t.path.split('/')
        const pagePath = pathArr[pathArr.length - 1]
        if (!this.subPath.includes(pagePath)) {
          this.keepAliveIncludes = []
          this.subPath = []
        }
        if (!this.keepAliveIncludes.includes(t.name)) {
          this.keepAliveIncludes.push(t.name)
          if (t.meta.subPath) {
            this.subPath = t.meta.subPath
          } else {
            this.subPath = []
          }
        }
      }
    }
  }
}
</script>

<style lang="less">
  .app-main {
  }

</style>

<style lang="less" scoped>
  @import "appMain";
</style>
