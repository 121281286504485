import request from '@/utils/request'

/**
 * 提交认证信息
 */
export const companyAuth = data => request.post('/api/console/company/auth', data)
/**
 * 获取认证信息
 */
export const getCompanyAuth = (params) => request.get('/api/console/company/query', { params })
