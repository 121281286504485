import request from '@/utils/request'

/**
 * 注册
 */
export const register = data => request.post('/api/console/regist', data)

/**
 * 验证昵称重复
 */
export const verifyNickName = data => request.post('/api/console/verifyNickName', data)
