// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./icon-return.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./icon-return-on.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".breadCrumbs[data-v-023ff05a]{font-size:14px;color:#909399}#page-title[data-v-023ff05a]{display:flex;flex-direction:row;align-items:center;justify-content:space-between;font-weight:400;height:14px;line-height:1;margin-top:30px;margin-left:30px}#page-title .page-title_content[data-v-023ff05a]{display:flex;font-size:14px;line-height:1}#page-title .jiantou-right[data-v-023ff05a]{display:inline-block;margin:0 5px 0 5px;color:#909399}#page-title .returnBtn[data-v-023ff05a]{cursor:pointer;width:60px;height:25px;font-size:14px;color:#909399;display:flex;flex-direction:row;align-items:center;justify-content:center}#page-title .returnBtn .icon-return-c[data-v-023ff05a]{margin-right:5px;width:20px;height:20px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:20px 20px}#page-title .returnBtn[data-v-023ff05a]:hover{color:#409eff}#page-title .returnBtn:hover .icon-return-c[data-v-023ff05a]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:20px 20px}", ""]);
// Exports
module.exports = exports;
