import Cookies from 'js-cookie'
import { getPageConfig } from 'api/common'
import { setting } from '@/setting'
const { baseInfo } = setting

const app = {
  state: {
    sidebar: {
      opened: !+Cookies.get('sidebarStatus'),
      withoutAnimation: false
    },
    device: 'desktop',
    pageConfig: {
      showRightTool: true,
      logoUrl: '',
      copyright: '',
      favicon: ''
    }
  },
  mutations: {
    // 设置网站配置信息
    SET_PAGE_CONFIG: (state, pageConfig) => {
      state.pageConfig = pageConfig
    },
    TOGGLE_SIDEBAR: state => {
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 1)
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    }
  },
  actions: {
    getPageConfig: async ({ commit }) => {
      const config = {
        logoUrl: baseInfo.logo,
        copyright: baseInfo.copyright,
        pathname: '/'
      }
      const pathname = window.location.pathname.replace(/#(.*?)$/, '').split('/').filter(item => item)
      const env = process.env.VUE_APP_RUNTIME
      if ((env === 'production' || env === 'test') && /zhongxin|viktor|xkd/g.test(pathname[0])) {
        getPageConfig(pathname[0]).then(({ data }) => {
          data.pathname = pathname[0]
          commit('SET_PAGE_CONFIG', { ...config, ...data, isCustom: true })
          if (data.favicon) {
            const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link')
            link.type = 'image/x-icon'
            link.rel = 'shortcut icon'
            link.href = data.favicon
            document.getElementsByTagName('head')[0].appendChild(link)
          }
        }).catch()
      } else {
        commit('SET_PAGE_CONFIG', {
          showRightTool: true,
          ...config
        })
      }
      // commit('SET_PAGE_CONFIG', {
      //   showRightTool: true,
      //   logoUrl: baseInfo.logo,
      //   copyright: baseInfo.copyright
      // })
    },
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    CloseSideBar ({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    ToggleDevice ({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    }
  }
}

export default app
