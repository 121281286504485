import { PROJECT_NAME } from '@/config'
import ywWorkRouterConfig from './ywWork'

const config = {
  [PROJECT_NAME.ywWork]: {
    ...ywWorkRouterConfig
  }
}

export const routerConfig = {
  ...config[process.env.VUE_APP_PROJECT_NAME]
}
